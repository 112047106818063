.page-container {
    background-color: #151515;
    max-width: 950px;
    margin: 0 auto;
    margin-bottom: 40px;
    border-radius: 20px;
    padding: 20px 30px;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.page-title {
    font-size: 2rem;
    padding: 30px 0px;
    color: rgba(255, 255, 255, 0.85);
}

/* Responsive */

@media only screen and (max-width: 1000px) {
    .page-container {
       margin-left: 40px;
       margin-right: 40px;
   }
}

@media only screen and (max-width: 700px) {
   .page-title {
       font-size: 2rem;
   }
}

@media only screen and (max-width: 500px) {
   .page-title {
       font-size: 1.7rem;
   }
}

@media only screen and (max-width: 425px) {
    .page-container {
        margin-left: 30px;
        margin-right: 30px;
    }
   .page-title {
       font-size: 1.5rem;
   }
}

@media only screen and (max-width: 375px) {
    .page-container {
        margin-left: 20px;
        margin-right: 20px;
    }
}