.container {
    max-width: 1240px;
    margin: 0 auto;
}

a {
    color: var(--white-color);
}


/* HEADINGS */

h1 {
    font-size: 6rem;
    font-weight: 800;
}

h2 {
    font-size: 3.6rem;
    font-weight: 700;
}

h3 {
    font-size: 3.5rem;
    font-weight: bold;
}

.section-title:after {
    display: block;
    content: ' ';
    height: 4px;
    background-color: var(--primary-color);
    width: 100px;
    margin: auto;
    margin-top: 30px;
}


/* MASTHEAD SECTION */

.masthead-section {
    background-image: linear-gradient( rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url("/src/assets/images/hero.jpg");
    background-size: cover;
    background-position: center;
    height: 100vh;
    background-attachment: fixed;
    position: relative;
}

.masthead-content {
    position: absolute;
    max-width: 1240px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.primary-logo {
    width: 250px;
}

.subheading-text {
    font-size: 2.4rem;
    font-weight: 500;
}

.cta-btn {
    font-family: inherit;
    display: inline-block;
    padding: 15px 50px;
    background-color: var(--primary-color);
    color: var(--black-color);
    text-decoration: none;
    border-radius: 40px;
    font-weight: 500;
    font-size: 1.8rem;
    transition: background-color 0.2s;
    border: none;
    cursor: pointer;
}

.cta-btn:hover,
.cta-btn:active {
    background-color: var(--primary-color-dark);
}


/* REVIEWS SECTION */

.reviews-section {
    margin-bottom: 8rem;
}

.box {
    background-color: var(--secondary-background-color);
    border: 2px solid var(--black-color);
    padding: 4rem;
    margin-right: 20px;
    border-radius: 10px;
}

.box:last-child {
    margin-right: 0px;
}

.review-text {
    min-height: 8rem;
    font-size: 1.8rem;
    font-style: italic;
}

.review-author {
    font-size: 1.6rem;
    color: var(--primary-color);
}


/* CONTACT SECTION */

.contact-section .container {
    height: 350px;
}

.map-box {
    border: 2px solid var(--black-color);
    border-right: none;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    -webkit-transform: translate3d(0px, 0px, 0px);
    -webkit-mask-image: -webkit-radial-gradient(white, black);
}

.map-box_img {
    width: 100%;
    min-height: 100%;
}

.contact-content {
    background-color: var(--secondary-background-color);
    border: 2px solid var(--black-color);
    border-left: none;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 50px;
}

.contact-content_header {
    padding-bottom: 3rem;
}

.contact-content_subheader {
    color: var(--primary-color);
    font-size: 1.8rem;
    font-weight: 500;
    padding-bottom: 2rem;
}

.contact-content_text {
    font-size: 1.6rem;
    padding-bottom: 1rem;
}


/* FOOTER SECTION */

.menu-box {
    margin: 0px 5px;
}

.menu-box_link {
    color: white;
}

.menu-box_link:hover {
    color: var(--primary-color);
}

#copyright-container {
    padding: 40px 0px 50px 0px;
}

#copyright-container_text {
    font-size: 1.6rem;
}

#nordify-link {
    color: var(--primary-color);
    text-decoration: none;
    font-weight: 500;
    text-transform: uppercase;
}

#nordify-link:hover {
    text-decoration: underline;
    text-underline-offset: 6px;
}


/* Responsive */

@media only screen and (max-width: 1300px) {
    .container {
        max-width: 1200px;
        margin: 0 auto;
        padding: 0px 20px;
    }
}

@media only screen and (max-width: 1140px) {
    .review-text {
        min-height: 7rem;
        font-size: 1.6rem;
    }
    .review-author {
        font-size: 1.4rem;
    }
}

@media only screen and (max-width: 1050px) {
    h2 {
        font-size: 3.4rem;
    }
    .box {
        padding: 3rem;
    }
}

@media only screen and (max-width: 900px) {
    .container {
        padding: 0px 40px;
        /* background-color: brown; */
    }
    h2 {
        font-size: 3rem;
    }
    .reviews-section {
        margin-bottom: 6rem;
    }
    .box-container {
        flex-direction: column;
    }
    .box {
        padding: 4rem;
        margin-right: 0;
        margin-bottom: 20px;
    }
    .review-text {
        min-height: 4rem;
        font-size: 1.8rem;
    }
    .review-author {
        font-size: 1.6rem;
    }
    .contact-section .container {
        flex-direction: column;
        height: 650px;
    }
    .map-box {
        border: 2px solid var(--black-color);
        border-bottom: none;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 0px;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 0px;
    }
    .contact-content {
        border: 2px solid var(--black-color);
        border-top: none;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 10px;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 10px;
    }
    /* FOOTER SECTION */
    #copyright-container_text {
        font-size: 1.6rem;
    }
}

@media only screen and (max-width: 700px) {
    h1 {
        font-size: 5.4rem;
    }
    h2 {
        font-size: 2.8rem;
    }
    .primary-logo {
        width: 210px;
    }
    .subheading-text {
        font-size: 2rem;
    }
    .cta-btn {
        padding: 15px 50px;
        font-size: 1.6rem;
    }
}

@media only screen and (max-width: 600px) {
    h2 {
        font-size: 2.4rem;
    }
    .cta-btn {
        padding: 15px 50px;
        font-size: 1.6rem;
    }
    .box {
        padding: 3rem;
    }
    .review-text {
        min-height: 5rem;
        font-size: 1.6rem;
    }
    .review-author {
        font-size: 1.6rem;
    }
    .contact-section .container {
        height: 600px;
    }
}

@media only screen and (max-width: 500px) {
    h1 {
        font-size: 4.8rem;
    }
    .primary-logo {
        width: 190px;
    }
    .subheading-text {
        font-size: 2rem;
    }
    .cta-btn {
        padding: 13px 40px;
        font-size: 1.6rem;
    }
    .box {
        padding: 3rem;
    }
    .review-text {
        min-height: 5rem;
        font-size: 1.4rem;
    }
    .review-author {
        font-size: 1.4rem;
    }
    .contact-section .container {
        height: 500px;
    }
    .contact-content {
        padding: 30px;
    }
    .contact-content_header {
        padding-bottom: 2rem;
    }
    .contact-content_subheader {
        font-size: 1.7rem;
        padding-bottom: 1.8rem;
    }
    .contact-content_text {
        font-size: 1.5rem;
        padding-bottom: 1rem;
    }
}

@media only screen and (max-width: 425px) {
    h1 {
        font-size: 4.2rem;
    }
    h2 {
        font-size: 1.8rem;
    }
    .section-title:after {
        width: 80px;
        margin-top: 25px;
    }
    .primary-logo {
        width: 170px;
    }
    .subheading-text {
        font-size: 1.6rem;
    }
    .cta-btn {
        padding: 12px 38px;
        font-size: 1.4rem;
    }
    /* FOOTER SECTION */
    #copyright-container {
        padding: 35px 0px;
    }
    #copyright-container_text {
        font-size: 1rem;
    }
}

@media only screen and (max-width: 375px) {
    h1 {
        font-size: 3.6rem;
    }
    .primary-logo {
        width: 150px;
    }
    .subheading-text {
        font-size: 1.4rem;
    }
    .cta-btn {
        padding: 12px 38px;
        font-size: 1.2rem;
    }
    .box {
        padding: 3rem;
    }
    .review-text {
        font-size: 1.2rem;
    }
    .review-author {
        margin-top: 1rem;
        font-size: 1.2rem;
    }
    .contact-section .container {
        height: 450px;
    }
    .contact-content_header {
        padding-bottom: 1.8rem;
        font-size: 3rem;
    }
    .contact-content_subheader {
        font-size: 1.6rem;
        padding-bottom: 1.6rem;
    }
    .contact-content_text {
        font-size: 1.4rem;
        padding-bottom: 1rem;
    }
}

@media only screen and (max-width: 320px) {
    .contact-section .container {
        height: 320px;
    }
    .contact-content {
        padding: 20px;
    }
    .contact-content_header {
        padding-bottom: 1.3rem;
        font-size: 2.4rem;
    }
    .contact-content_subheader {
        font-size: 1.3rem;
        padding-bottom: 1.4rem;
    }
    .contact-content_text {
        font-size: 1.1rem;
        padding-bottom: 0.8rem;
    }
    /* FOOTER SECTION */
    #copyright-container {
        padding: 30px 0px;
    }
    #copyright-container_text {
        font-size: 0.8rem;
    }
}