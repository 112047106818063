.form-box {
    display: flex;
    flex-wrap: wrap;
}

.form-box:first-child {
    margin-bottom: 5px;
}

.form-box .form__group {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    margin-right: 20px;
}

.form-box .form__group:last-child {
    margin-right: 0px;
    margin-left: 20px;
}

.form__label {
    font-size: 1.4rem;
    margin-bottom: 10px;
    opacity: 0.7;
}

.form__input {
    font-family: inherit;
    background-color: var(--secondary-background-color);
    padding: 18px 0px;
    padding-left: 10px;
    border: 2px solid black;
    border-radius: 10px;
    color: rgba(255, 255, 255, 0.8);
}

.form__input:focus {
    outline: none;
}

.form-btn {
    font-family: inherit;
    margin-top: 15px;
    background-color: var(--primary-color);
    color: var(--black-color);
    display: inline-block;
    border: none;
    padding: 15px 25px;
    text-decoration: none;
    border-radius: 5px;
    font-weight: 600;
    font-size: 1.4rem;
    align-self: center;
    border: none;
    cursor: pointer;
    margin-bottom: 20px;
}

.form-btn:hover {
    background-color: var(--primary-color-dark);
}

.error-message {
    background-color: rgba(255, 0, 0, 0.5);
    padding: 20px;
    margin-bottom: 20px;
    font-size: 1.4rem;
    border-radius: 10px;
}

.hide-mssg {
    display: none;
}

.show-mssg {
    display: block;
}


/* Responsive */

@media only screen and (max-width: 700px) {
    .form-box {
        flex-direction: column;
    }
    .form-box:first-child {
        margin-bottom: 0;
    }
    .form-box .form__group {
        margin-right: 0;
    }
    .form-box .form__group:last-child {
        margin-left: 0;
    }
}

@media only screen and (max-width: 500px) {
    .form-btn {
        margin-top: 10px;
        width: 100%;
        padding: 18px 25px;
        border-radius: 10px;
        font-weight: 600;
    }
}

@media only screen and (max-width: 425px) {
    .form__label {
        font-size: 1.2rem;
    }
    .form__input {
        font-size: 1.2rem;
        padding: 15px 0px;
        padding-left: 10px;
    }
    .form-btn {
        margin-top: 5px;
        font-size: 1.4rem;
        padding: 15px 25px;
    }
}

@media only screen and (max-width: 375px) {
    .form-btn {
        font-size: 1.2rem;
    }
}