.appointments-list-container {
    margin-top: 20px;
}


/* Date picker input */

.custom-input-box {
    display: flex;
    align-items: center;
}

.custom-input-title {
    margin-right: 10px;
    font-size: 1.6rem;
}

.custom-input {
    background-color: #151515;
    border: 0;
    padding: 10px 25px;
    font-size: 1.6rem;
    color: white;
    font-family: inherit;
    border-radius: 5px;
    border: 1px solid var(--border-color);
}


/* Date picker */

.react-datepicker__input-container input:focus {
    outline: none;
}

.react-datepicker {
    font-size: 1.3rem !important;
    font-family: inherit;
    background-color: #151515;
    border-color: var(--border-color);
}

.react-datepicker__navigation {
    color: #3dcc4a;
}

.react-datepicker__current-month {
    color: var(--primary-color-dark);
    font-size: 1.5rem !important;
}

.react-datepicker__header {
    background-color: var(--primary-background-color);
    padding-top: 6px !important;
    border-color: var(--border-color);
}

.react-datepicker__day-name {
    color: var(--primary-color-dark);
    margin: 0.7rem !important;
}

.react-datepicker__day {
    color: rgba(255, 255, 255, 0.8);
    margin: 0.7rem !important;
}

.react-datepicker__day:hover {
    color: #151515;
}

.react-datepicker__day--selected {
    background-color: var(--primary-color-dark);
}

.react-datepicker__day--selected:hover {
    background-color: var(--primary-color-dark);
}

.react-datepicker__triangle {
    display: none;
}


/* Responsive */

@media only screen and (max-width: 500px) {
    .custom-input-title {
        font-size: 1.4rem;
    }
    .custom-input {
        font-size: 1.4rem;
    }
}

@media only screen and (max-width: 425px) {
    .custom-input-title {
        font-size: 1.3rem;
    }
    .custom-input {
        font-size: 1.3rem;
    }
}