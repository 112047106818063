.confirmation-page .page-container {
    background-color: #151515;
    max-width: 500px;
    margin: 0 auto;
    padding: 50px 20px;
    border-radius: 20px;
    text-align: center;
}

.confirmation-page .checkmark-img {
    width: 70px;
    margin-bottom: 24px;
}

.confirmation-page h4 {
    font-size: 24px;
    margin-bottom: 18px;
}

.confirmation-page p {
    font-size: 16px;
    margin-bottom: 28px;
}

.confirmation-page .done-cta {
    font-family: inherit;
    background-color: var(--primary-color);
    color: var(--black-color);
    display: inline-block;
    padding: 10px 45px;
    text-decoration: none;
    border-radius: 50px;
    font-weight: bold;
    font-size: 1.7rem;
    align-self: center;
    border: none;
    cursor: pointer;
}

@media only screen and (max-width: 550px) {
    .confirmation-page .page-container {
        margin: 0 40px;
        padding: 40px 20px;
    }
    .confirmation-page .checkmark-img {
        width: 50px;
        margin-bottom: 20px;
    }
    .confirmation-page h4 {
        font-size: 2rem;
        margin-bottom: 15px;
    }
    .confirmation-page p {
        font-size: 1.6rem;
        margin-bottom: 20px;
    }
    .confirmation-page .done-cta {
        padding: 10px 45px;
        font-size: 1.6rem;
    }
}

@media only screen and (max-width: 425px) {
    .confirmation-page .page-container {
        margin: 0 40px;
        padding: 40px 20px;
    }
    .confirmation-page .checkmark-img {
        width: 45px;
        margin-bottom: 20px;
    }
    .confirmation-page h4 {
        font-size: 1.8rem;
        margin-bottom: 15px;
    }
    .confirmation-page p {
        font-size: 1.4rem;
        margin-bottom: 25px;
    }
    .confirmation-page .done-cta {
        padding: 8px 40px;
        font-size: 1.6rem;
    }
}

@media only screen and (max-width: 375px) {
    .confirmation-page .page-container {
        margin: 0 30px;
        padding: 35px 20px;
    }
    .confirmation-page .checkmark-img {
        width: 45px;
        margin-bottom: 20px;
    }
    .confirmation-page h4 {
        font-size: 1.5rem;
        margin-bottom: 12px;
    }
    .confirmation-page p {
        font-size: 1.3rem;
        margin-bottom: 20px;
    }
    .confirmation-page .done-cta {
        padding: 8px 45px;
        font-size: 1.5rem;
    }
}