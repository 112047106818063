/* Height*/

.u-full-height {
    height: 100% !important;
}


/* Display*/

.u-display-inline-block {
    display: inline-block !important;
}

.u-display-block {
    display: block !important;
}


/* Flexbox */

.u-display-flex {
    display: flex !important;
    flex-wrap: wrap !important;
}

.u-justify-content-flex-start {
    justify-content: flex-start !important;
}

.u-justify-content-flex-end {
    justify-content: flex-end !important;
}

.u-justify-content-center {
    justify-content: center !important;
}

.u-justify-content-space-between {
    justify-content: space-between !important;
}

.u-justify-content-space-around {
    justify-content: space-around !important;
}

.u-align-items-flex-start {
    align-items: flex-start !important;
}

.u-align-items-flex-end {
    align-items: center !important;
}

.u-align-items-center {
    align-items: center !important;
}

.u-align-self-flex-start {
    align-self: flex-start !important;
}

.u-align-self-flex-end {
    align-self: flex-end !important;
}

.u-align-self-center {
    align-self: center !important;
}

.u-flex-direction-row {
    flex-direction: row !important;
}

.u-flex-direction-column {
    flex-direction: column !important;
}

.u-flex-1 {
    /* allows boxes to share space equally */
    flex: 1;
}

.u-flex-2 {
    flex: 2;
}

.u-flex-3 {
    flex: 3;
}

.u-flex-4 {
    flex: 4;
}

.u-flex-5 {
    flex: 5;
}


/* Text Align Center*/

.u-text-align-center {
    text-align: center !important;
}

.u-text-align-start {
    text-align: start !important;
}

.u-text-align-end {
    text-align: end !important;
}


/* Ul*/

.u-list-style-none {
    list-style: none !important;
}


/* Text Decoration*/

.u-text-decoration-none {
    text-decoration: none !important;
}


/* Overflow*/

.u-overflow-hidden {
    overflow: hidden !important;
}

.u-overflow-x-hidden {
    overflow-x: hidden !important;
}

.u-overflow-y-hidden {
    overflow-y: hidden !important;
}


/* Margins */

.u-margin-bottom-small {
    margin-bottom: 1.5rem !important;
}

.u-margin-bottom-medium {
    margin-bottom: 3rem !important;
}

.u-margin-bottom-big {
    margin-bottom: 6rem !important;
}

.u-margin-bottom-huge {
    margin-bottom: 8rem !important;
}

.u-margin-top-small {
    margin-top: 1.5rem !important;
}

.u-margin-top-medium {
    margin-top: 3rem !important;
}

.u-margin-top-big {
    margin-top: 6rem !important;
}

.u-margin-top-huge {
    margin-top: 8rem !important;
}

@media only screen and (max-width: 700px) {
    .u-margin-bottom-small {
        margin-bottom: 1.2rem !important;
    }
    .u-margin-bottom-medium {
        margin-bottom: 2.7rem !important;
    }
}

@media only screen and (max-width: 500px) {
    .u-margin-bottom-small {
        margin-bottom: 1rem !important;
    }
    .u-margin-bottom-medium {
        margin-bottom: 2.5rem !important;
    }
}

@media only screen and (max-width: 375px) {
    .u-margin-bottom-small {
        margin-bottom: 0.8rem !important;
    }
    .u-margin-bottom-medium {
        margin-bottom: 2rem !important;
    }
}

@media only screen and (max-width: 320px) {
    .u-margin-top-huge {
        margin-top: 6rem !important;
    }
    .u-margin-bottom-huge {
        margin-bottom: 6rem !important;
    }
}