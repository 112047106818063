.header-btn {
    background-color: #151515;
    border: 0;
    padding: 10px;
    display: flex;
    border: 1px solid var(--border-color);
    cursor: pointer;
}

.header-btn-icon {
    margin-right: 5px;
    font-size: 1.4rem;
    color: var(--primary-color);
}

.header-btn-title {
    font-size: 1.4rem;
    font-weight: 600;
    color: rgba(255, 255, 255, 0.8);
}
