.time-container {
    border-radius: 20px;
}

.switch-box {
    display: flex;
    margin-bottom: 30px;
}

.time-selector-box {
    display: flex;
}

.time-selector-box-hide {
    display: none;
}

.time-selector-box-show {
    display: flex;
}

.save-btn-box {
    margin: 20px 0px;
}

.save-btn {
    background-color: var(--primary-color);
    border: 0;
    padding: 10px 25px;
    border-radius: 10px;
    font-weight: bold;
    font-size: 1.4rem;
    cursor: pointer;
    color: #000;
}