.client-form {
    border-top: 1px solid var(--border-color);
    border-bottom: 1px solid var(--border-color);
    padding-top: 20px;
}

.date-time-cutom-input {
    font-family: inherit;
    background-color: var(--secondary-background-color);
    border: 1px solid black;
    color: rgba(255, 255, 255, 0.8);
    border-radius: 10px;
    font-size: 1.4rem;
    padding: 18px;
    width: 100%;
    text-align: left;
}

.new-appt-btn-box .new-appt-btn {
    background-color: var(--primary-color);
    border: 0;
    padding: 12px 25px;
    font-size: 1.4rem;
    font-weight: bold;
    border-radius: 10px;
    cursor: pointer;
    color: #000;
    margin: 20px 0px;
}