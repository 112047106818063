.signin-page {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
}

.sigin-container {
    background-color: #151515;
    padding: 25px;
    border-radius: 20px;
    width: 400px;
}

.signin-header {
    margin-bottom: 20px;
}

.siginin-form label {
    display: block;
    margin-bottom: 10px;
    font-size: 1.4rem;
}

.siginin-form input {
    display: block;
    font-size: 1.4rem;
    width: 100%;
    font-family: inherit;
    background-color: #1B1B1B;
    padding: 15px 0px;
    padding-left: 10px;
    border: 2px solid var(--border-color);
    border-radius: 10px;
    color: rgba(255, 255, 255, 0.8);
    margin-bottom: 15px;
}

.siginin-form input:focus {
    outline: none;
}

.siginin-form button {
    font-family: inherit;
    margin-top: 10px;
    background-color: var(--primary-color);
    color: var(--black-color);
    display: inline-block;
    border: none;
    padding: 12px 25px;
    text-decoration: none;
    border-radius: 5px;
    font-weight: 600;
    font-size: 1.4rem;
    align-self: center;
    border: none;
    cursor: pointer;
}

@media only screen and (max-width: 500px) {
    .sigin-container {
        margin-left: 30px;
        margin-right: 30px;
    }
    .siginin-form label {
        font-size: 1.2rem;
    }
    .siginin-form input {
        font-size: 1.2rem;
    }
    .siginin-form button {
        font-size: 1.2rem;
    }
}