.hours-container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

.hour-container {
    background-color: var(--secondary-background-color);
    border: 1px solid black;
    padding: 30px 20px;
    border-radius: 10px;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.hour-container .day-name {
    font-size: 1.6rem;
    font-weight: bold;
}

.hours-box {
    display: flex;
    align-items: center;
}

.hours-box .time-p {
    font-size: 1.4rem;
    margin-right: 4px;
}


/* RESPONSIVE */

@media only screen and (max-width: 425px) {
    .hour-container .day-name {
        font-size: 1.4rem;
    }
    .hours-box .time-p {
        font-size: 1.2rem;
    }
}