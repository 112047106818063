.appointment-booking-page .page-container {
    background-color: #151515;
    max-width: 950px;
    margin: 0 auto;
    margin-bottom: 6rem;
    padding: 20px 0;
    border-radius: 20px;
}


/* GRID */

.row {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
}

.row-middle {
    align-items: center;
}

.col {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
}

.col-start {
    justify-content: flex-start;
    text-align: left;
}

.col-center {
    justify-content: center;
    text-align: center;
}

.col-end {
    justify-content: flex-end;
    text-align: right;
}


/* Calendar */

.calendar {
    display: block;
    position: relative;
    width: 100%;
    padding-bottom: 3.5rem;
    border-bottom: 1px solid var(--border-color);
}

.calendar .header {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 125%;
    padding: 1.5em 0;
    border-bottom: 1px solid var(--border-color);
    color: var(--primary-color-dark);
}

.calendar .days {
    text-transform: uppercase;
    font-weight: 400;
    color: var(--primary-color-dark);
    font-size: 90%;
    padding: 0.75em 0;
    border-bottom: 1px solid var(--border-color);
}

.calendar .body .cell {
    position: relative;
    height: 4em;
    border-right: 1px solid var(--border-color);
    overflow: hidden;
    cursor: pointer;
    background: var(--primary-background-color);
    transition: 0.25s ease-out;
    font-size: 1.5em;
}

.calendar .body .cell:hover {
    background: var(--secondary-background-color);
    transition: 0.5s ease-out;
}

.calendar .body .selected {
    border-left: 10px solid transparent;
    border-image: linear-gradient(45deg, var(--primary-color-dark) 0%, var(--primary-color) 40%);
    border-image-slice: 1;
}

.calendar .body .row {
    border-bottom: 1px solid var(--border-color);
}

.calendar .body .cell .number {
    position: absolute;
    font-size: 82.5%;
    line-height: 1;
    top: 0.75em;
    right: 0.75em;
    font-weight: 700;
    color: var(--primary-color-dark);
}

.calendar .body .cell .bg {
    font-weight: 700;
    line-height: 1;
    color: var(--primary-color);
    opacity: 0;
    font-size: 8em;
    position: absolute;
    top: -0.2em;
    right: -0.05em;
    transition: 0.25s ease-out;
    letter-spacing: -0.07em;
}

.calendar .body .cell:hover .bg,
.calendar .body .selected .bg {
    opacity: 0.05;
    transition: 0.5s ease-in;
}

.calendar .body .col {
    flex-grow: 0;
    flex-basis: calc(100% / 7);
    width: calc(100% / 7);
}


/* Timeslot */

.timeslot-list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: left;
    margin-top: 30px;
    margin-bottom: 10px;
    margin-left: 20px;
    margin-right: 20px;
}

.timeslot {
    background-color: var(--primary-background-color);
    padding: 10px;
    flex-basis: 10%;
    margin-right: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    text-align: center;
    cursor: pointer;
    border: 1px solid var(--primary-background-color);
}

.timeslot.selected {
    background-color: var(--secondary-background-color);
    color: #fff;
    border: 1px solid var(--border-color);
}

.timeslot.notAvailable {
    cursor: default;
}

.timeslot.notAvailable h3 {
    color: #888888;
    text-decoration: line-through;
}

.timeslot h3 {
    display: block;
    font-size: 1.3em;
    padding: 3px 0;
    color: var(--primary-color);
}

.timeslot p {
    font-size: 0.8em;
}


/* Button */

.btn {
    font-family: inherit;
    margin: 10px 20px;
    background-color: var(--primary-color);
    color: var(--black-color);
    border: none;
    padding: 10px 25px;
    border-radius: 5px;
    font-weight: 600;
    font-size: 1.4rem;
    border: none;
    cursor: pointer;
}

.btn:hover {
    background-color: var(--primary-color-dark);
}

.hide-btn {
    display: none;
}

.closed-view {
    font-size: 3rem;
    font-weight: bold;
    padding-bottom: 20px;
    opacity: 0.7;
}