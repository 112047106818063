.actions-list {
    display: flex;
    align-items: center;
}

.action-btn {
    display: flex;
    background-color: var(--primary-color);
    border: none;
    margin-left: 10px;
    padding: 8px;
    border-radius: 5px;
    font-size: 1.4rem;
    cursor: pointer;
}

.action-btn .action-btn-icon {
    color: #000;
}


/* Responsive */

@media only screen and (max-width: 375px) {
    .action-btn:first-child {
        margin-left: 0px;
    }
}