nav {
    background-color: #151515;
    padding: 30px 0px;
    margin-bottom: 60px;
}

.nav-logo {
    width: 180px;
    cursor: pointer;
}

@media only screen and (max-width: 600px) {
    nav {
        padding: 20px 0px;
        margin-bottom: 40px;
    }
    .nav-logo {
        width: 160px;
    }
}