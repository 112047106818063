@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
:root {
    --primary-color: #DDB967;
    --primary-color-dark: #cfab56;
    --primary-background-color: #1B1B1B;
    --secondary-background-color: #101010;
    --nordify-primary-color: #E0C49F;
    --white-color: #ffffff;
    --black-color: #000000;
    --border-color: rgb(36, 36, 36);
}

*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    /* This defines what 1rem is */
    /*1 rem = 10px; 10px/16px = 62.5%*/
    font-size: 62.5%;
}

body {
    background-color: var(--primary-background-color);
    color: var(--white-color);
    font-family: Montserrat, Arial, Helvetica, sans-serif;
    overflow-x: hidden;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

@media only screen and (max-width: 500px) {
    nav {
        margin-bottom: 40px;
    }
}