.admin-nav {
    background-color: #151515;
    padding: 35px;
    margin-bottom: 60px;
}

.admin-nav-container {
    max-width: 1240px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.admin-logo-box {
    width: 200px;
    cursor: pointer;
}

.admin-logo-box .admin-primary-logo {
    width: 100%;
}

.admin-nav-top {
    display: flex;
    align-items: center;
}

.admin-nav-burger {
    display: none;
    opacity: 0.9;
}

.burger-img {
    width: 40px;
}

.admin-nav-menu {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.admin-nav-menu__item {
    margin-left: 10px;
}

.admin-nav-menu__item:first-child {
    margin-left: 0;
}

.nav-menu__link {
    background-color: transparent;
    padding: 15px;
    border: none;
    color: white;
    font-family: inherit;
    font-size: 1.4rem;
    cursor: pointer;
}

.nav-menu__link:hover {
    color: var(--primary-color);
}

.active-link {
    color: var(--primary-color-dark);
}

@media only screen and (max-width: 700px) {
    .admin-nav {
        padding: 0;
        margin-bottom: 40px;
    }
    .admin-nav-container {
        flex-direction: column;
    }
    .admin-nav-top {
        padding: 25px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .admin-logo-box {
        width: 140px;
    }
    .admin-nav-burger {
        display: block;
    }
    .burger-img {
        width: 30px;
        cursor: pointer;
    }
    .admin-nav-menu {
        width: 100%;
        margin-bottom: 20px;
    }
    .admin-nav-menu-list {
        width: 100%;
        display: flex;
        flex-direction: column;
        text-align: center;
    }
    .admin-nav-menu__item {
        width: 100%;
        margin-left: 0;
    }
    .nav-menu__link {
        width: 100%;
        font-size: 1.3rem;
        font-weight: 500;
    }
    .show-nav {
        display: block;
    }
    .hide-nav {
        display: none;
    }
}