.time-selector {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.time-selector:first-child {
    margin-right: 20px;
}

.time-selector-label {
    font-size: 1.4rem;
    margin-right: 10px;
}

.custom-time-input {
    background-color: var(--secondary-background-color);
    border: 1px solid black;
    padding: 10px 20px;
    font-size: 1.4rem;
    color: rgba(255, 255, 255, 0.8);
    border-radius: 10px;
}


/* RESPONSIVE */

@media only screen and (max-width: 425px) {
    .time-selector {
        flex-direction: column;
        align-items: start;
        width: 50%;
    }
    .time-selector-label {
        margin-right: 0;
        margin: 10px 0px;
        min-width: 50px;
        padding-left: 5px;
    }
    .custom-time-input {
        width: 100%;
        padding-top: 15px;
        padding-bottom: 15px;
        text-align: left;
    }
}