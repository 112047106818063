.service-box {
    background-color: var(--secondary-background-color);
    padding: 20px 40px;
    border-radius: 10px;
    margin-bottom: 20px;
    border: 1px solid black;
}

.service-info {
    flex: 1;
}

.service-info__name {
    font-size: 2rem;
    margin-bottom: 10px;
}

.service-info p {
    font-size: 1.4rem;
    opacity: 0.6;
}

.cta-box {
    display: flex;
}

.service-cta {
    font-family: inherit;
    background-color: var(--primary-color);
    color: var(--black-color);
    display: inline-block;
    padding: 10px 30px;
    text-decoration: none;
    border-radius: 5px;
    font-weight: 500;
    font-size: 1.7rem;
    align-self: center;
    border: none;
    cursor: pointer;
}


/* Responsive */

@media only screen and (max-width: 700px) {
    .service-box {
        padding: 25px;
        margin-bottom: 15px;
    }
    .service-info__name {
        font-size: 1.8rem;
    }
    .service-info p {
        font-size: 1.3rem;
    }
}

@media only screen and (max-width: 500px) {
    .service-box {
        padding: 20px;
    }
    .service-info__name {
        font-size: 1.6rem;
    }
    .service-info p {
        font-size: 1.2rem;
    }
    .service-cta {
        padding: 8px 28px;
        border-radius: 30px;
        font-size: 1.7rem;
    }
}

@media only screen and (max-width: 425px) {
    .service-box {
        border-radius: 15px;
        padding: 20px;
        margin-bottom: 12px;
    }
    .service-info__name {
        font-size: 1.3rem;
    }
    .service-info p {
        font-size: 1.1rem;
    }
    .service-cta {
        padding: 8px 25px;
        font-size: 1.6rem;
    }
}

@media only screen and (max-width: 375px) {
    .service-box {
        flex-direction: column;
        margin-bottom: 15px;
    }
    .service-info__name {
        font-size: 1.5rem;
        margin-bottom: 8px;
    }
    .service-info p {
        font-size: 1.2rem;
        margin-bottom: 20px;
    }
    .cta-box {
        display: flex;
    }
    .service-cta {
        flex: 1;
        padding: 8px 0;
        border-radius: 5px;
        font-weight: 600;
        font-size: 1.6rem;
    }
}