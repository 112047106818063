.appointment-cancel-page .page-container {
    background-color: #151515;
    max-width: 450px;
    margin: 0 auto;
    border-radius: 20px;
}

.appointment-cancel-page .page-container .cancel-form h2 {
    font-size: 2.6rem;
}

.appointment-cancel-page .page-container .cancel-form h2:after {
    display: block;
    content: ' ';
    height: 4px;
    background-color: var(--primary-color);
    width: 70px;
    margin: 0;
    margin-top: 20px;
}

.appointment-cancel-page .cancel-form {
    background-color: #151515;
    border-radius: 10px;
    padding: 10px 0px;
}

.cancel-form-message {
    margin-bottom: 15px;
}

.cancel-form__group .cancel-form__input {
    font-family: inherit;
    background-color: #1B1B1B;
    padding: 18px 0px;
    padding-left: 10px;
    border: 2px solid var(--border-color);
    border-radius: 10px;
    color: rgba(255, 255, 255, 0.8);
    width: 100%;
}

.cancel-form__group input[type=text]:focus,
.cancel-form__group input[type=tel]:focus,
.cancel-form__group input[type=email]:focus {
    outline: none;
}

.cancel-button {
    font-family: inherit;
    margin-top: 25px;
    background-color: var(--primary-color);
    color: var(--black-color);
    display: inline-block;
    border: none;
    padding: 16px 0;
    text-decoration: none;
    border-radius: 10px;
    font-weight: 600;
    font-size: 1.4rem;
    align-self: center;
    border: none;
    cursor: pointer;
    width: 100%;
}

@media only screen and (max-width: 700px) {
    .appointment-cancel-page .page-container .cancel-form h2 {
        font-size: 2rem;
    }
    .appointment-cancel-page .page-container .cancel-form h2:after {
        width: 60px;
    }
}

@media only screen and (max-width: 500px) {
    .appointment-cancel-page .page-container {
        margin: 0px 20px;
    }
    .appointment-cancel-page .page-container .cancel-form h2 {
        font-size: 1.7rem;
    }
    .appointment-cancel-page .page-container .cancel-form h2:after {
        width: 50px;
    }
}

@media only screen and (max-width: 450px) {
    .cancel-form__group .cancel-form__input {
        font-size: 1.2rem;
    }
    .cancel-button {
        margin-top: 20px;
        font-size: 1.2rem;
    }
}

@media only screen and (max-width: 425px) {
    .appointment-cancel-page .page-container .cancel-form h2 {
        font-size: 1.5rem;
    }
    .appointment-cancel-page .page-container .cancel-form h2:after {
        width: 40px;
        margin-top: 15px;
    }
}