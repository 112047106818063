.admin-appointment {
    background-color: var(--secondary-background-color);
    border: 1px solid black;
    padding: 20px;
    border-radius: 20px;
    margin-bottom: 15px;
}

.admin-appointment div {
    margin-bottom: 10px;
    font-size: 1.5rem;
}

.cancel-btn {
    margin-top: 10px;
    font-family: inherit;
    background-color: var(--primary-color);
    color: var(--black-color);
    display: inline-block;
    padding: 5px 25px;
    text-decoration: none;
    border-radius: 5px;
    font-weight: 500;
    font-size: 1.4rem;
    align-self: center;
    border: none;
    cursor: pointer;
}